<template>
    <b-container fluid class="acc-container">
        <b-row class="acc-info-row">
            <b-col xl="8" lg="8" md="12">
                <h2> {{ $t('headings.account_details') }} </h2>
                <div class="account-info-container">
                    <div class="acc-info-wrapper">
                        <div class="acc-info-line" v-for="user_data in user_data_fields.data_column" :key="user_data.id">
                            <div class="acc-info-tag">
                                <i :class="user_data.dataIcon"></i>
                                <span> {{user_data.dataPlaceholder}} </span>
                            </div>
                            <div class="acc-info-content">
                                <span> {{user_data.dataValue}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col xl="8" lg="8" md="12">
                <h2> {{ $t('headings.password_change') }} </h2>
                <div class="change-password-wrapper">
                    <form class="change-ps" method="POST" id="change-password-form">
                        <div class="input-group">
                            <!-- v-model="oldPassword" -->
                            <input type="password" placeholder="Parola curenta" name="old_password" id="old-password">
                        </div>
                        <div class="input-group">
                            <!-- v-model="newPassword" -->
                            <input type="password" name="password" placeholder="Parola noua" id="new-password">
                        </div>
                        <div class="input-group">
                            <!-- v-model="confirmPassword" -->
                            <input type="password" name="password_confirmation" placeholder="Confirma parola" id="repeat-new-password">
                        </div>
                        <div class="general-error" v-if="errors.length > 0">
                            <i class="fas fa-exclamation-triangle"></i>
                            <p v-for="(error, key) in errors" :key="key"> {{error}} </p>
                        </div>
                        <div class="button-container">
                            <button type="button" @click="changePassword()">
                                {{ $t('placeholder.change_password') }}
                            </button>
                        </div>
                    </form>
                </div>
                <!-- <div class="acc-achievments-wrapper">
                    <div class="acc-achievment-box inprogress-bckg">
                        <span> <i class="far fa-clock"></i> </span>
                        <span> Comenzi in desfasurare </span>
                        <span> 30 </span>
                    </div>
                    <div class="acc-achievment-box delivered-bckg">
                        <span> <i class="fas fa-check"></i> </span>
                        <span> Comenzi livrate </span>
                        <span> 130 </span>
                    </div>
                    <div class="acc-achievment-box canceled-bckg">
                        <span> <i class="fas fa-times"></i> </span>
                        <span> Comenzi anulate </span>
                        <span> 12 </span>
                    </div>
                </div> -->
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import LoginService from '@/api-services/login.service.js'
export default {
  name: 'Account',
  data () {
    return {
      user: null,
      errors: []
    }
  },
  methods: {
    changePassword () {
      this.errors = []
      let passwordData = new FormData(document.getElementById('change-password-form'))
      LoginService.changePassword(passwordData)
        .then((response) => {
          this.$swal.fire({
            icon: 'success',
            title: this.$t('alert.positive.password_change'),
            showConfirmButton: false,
            timer: 2500
          })
          window.$('#change-password-form').trigger('reset')
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            let err = error.response.data.errors
            for (let i in err) {
              this.errors.push(err[i][0])
            }
          }
        })
    }
  },
  computed: {
    user_data_fields () {
      return {
        data_column: [
          {
            dataPlaceholder: this.$t('placeholder.name'),
            dataValue: (this.user) ? this.user.name : null,
            dataIcon: 'fas fa-signature'
          },
          {
            dataPlaceholder: this.$t('placeholder.email'),
            dataValue: (this.user) ? this.user.email : null,
            dataIcon: 'fas fa-envelope'
          }
        ]
      }
    }
  },
  async created () {
    this.user = this.$store.getters.user
    if (this.user === undefined || !this.user) {
      this.user = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
    }
    this.$store.dispatch('hideLoading')
  }
}
</script>
